import { useState, useEffect } from 'react';
import * as journalService from '../services/journalService';

export const useJournalEntries = () => {
  const [journalEntries, setJournalEntries] = useState({});
  const [savedDates, setSavedDates] = useState([]);

  useEffect(() => {
    const entries = journalService.getJournalEntries();
    setJournalEntries(entries);
    setSavedDates(Object.keys(entries));
  }, []);

  const updateEntry = (date, field, value) => {
    const updatedEntries = journalService.updateJournalEntry(journalEntries, date, field, value);
    setJournalEntries(updatedEntries);
    setSavedDates(Object.keys(updatedEntries));
  };

  const deleteEntry = (date) => {
    const updatedEntries = journalService.deleteJournalEntry(journalEntries, date);
    setJournalEntries(updatedEntries);
    setSavedDates(Object.keys(updatedEntries));
  };

  const deleteAllEntries = () => {
    const emptyEntries = journalService.deleteAllJournalEntries();
    setJournalEntries(emptyEntries);
    setSavedDates([]);
  };

  return {
    journalEntries,
    savedDates,
    updateEntry,
    deleteEntry,
    deleteAllEntries
  };
};