import React from 'react';
import styles from './DateSelector.module.css';

const DateSelector = ({ selectedDate, setSelectedDate, savedDates, isSelectOpen, setIsSelectOpen }) => {
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setIsSelectOpen(false);
  };

  const renderDateOptions = () => {
    const today = new Date();
    const options = [];
    for (let i = 0; i < 30; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const formattedDate = date.toISOString().split('T')[0];
      const hasEntry = savedDates.includes(formattedDate);
      options.push(
        <option key={formattedDate} value={formattedDate}>
          {formattedDate} {hasEntry ? '✅' : ''}
        </option>
      );
    }
    return options;
  };

  return (
    <div className={styles.dateSelector}>
      <div className={styles.dateInput}>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          max={new Date().toISOString().split('T')[0]}
        />
        {savedDates.includes(selectedDate) && <span className={styles.checkmark}>✅</span>}
      </div>
      <div className={styles.browseEntries}>
        <select
          value={isSelectOpen ? selectedDate : ""}
          onChange={handleDateChange}
          onFocus={() => setIsSelectOpen(true)}
          onBlur={() => setIsSelectOpen(false)}
        >
          <option value="" disabled>Browse entries</option>
          {renderDateOptions()}
        </select>
      </div>
    </div>
  );
};

export default DateSelector;