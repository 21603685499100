import React from 'react';
import styles from './TopBar.module.css';

const TopBar = ({ darkMode, setDarkMode, isMenuOpen, setIsMenuOpen, exportAllEntriesToCSV, deleteAllEntries }) => {
  return (
    <div className={styles.topBar}>
      <h1><img src="/journal-icon.png" alt="Journal Icon" />Daily Journal</h1>
      <div className={styles.topBarRight}>
        <div className={styles.darkModeToggle}>
          <span 
            role="img" 
            aria-label="Light mode"
            onClick={() => setDarkMode(false)}
            style={{ opacity: darkMode ? 0.5 : 1, cursor: 'pointer' }}
          >
            ☀️
          </span>
          <span 
            role="img" 
            aria-label="Dark mode"
            onClick={() => setDarkMode(true)}
            style={{ opacity: darkMode ? 1 : 0.5, cursor: 'pointer' }}
          >
            🌙
          </span>
        </div>
        <div className={styles.menuDropdown}>
          <span 
            role="img" 
            aria-label="Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{ cursor: 'pointer' }}
          >
            ⋮
          </span>
          {isMenuOpen && (
            <div className={styles.dropdownContent}>
              <button onClick={exportAllEntriesToCSV}>Export All Entries (CSV)</button>
              <button onClick={deleteAllEntries} className={styles.deleteAllButton}>Delete All Entries</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;