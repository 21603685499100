import React, { useState, useEffect } from 'react';
import styles from './ContactIcon.module.css';

const ContactIcon = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleTouchStart = () => {
      const wrapper = document.querySelector(`.${styles.contactIconWrapper}`);
      if (wrapper) {
        wrapper.style.right = '0';
      }
    };

    const contactIcon = document.querySelector(`.${styles.contactIcon}`);
    if (contactIcon) {
      contactIcon.addEventListener('touchstart', handleTouchStart);
    }

    return () => {
      if (contactIcon) {
        contactIcon.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, []);

  return (
    <div 
      className={styles.contactIcon}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span role="img" aria-label="Contact" className={styles.icon}>📧</span>
      <span className={styles.text}>Contact</span>
      {isHovered && (
        <div className={styles.emailTooltip}>
          hungry@hungry-minded.com
        </div>
      )}
    </div>
  );
};

export default ContactIcon;