const STORAGE_KEY = 'journalEntries';

export const getJournalEntries = () => {
  const savedEntries = localStorage.getItem(STORAGE_KEY);
  return savedEntries ? JSON.parse(savedEntries) : {};
};

export const saveJournalEntries = (entries) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(entries));
};

export const updateJournalEntry = (entries, date, field, value) => {
  const updatedEntries = {
    ...entries,
    [date]: {
      ...entries[date],
      [field]: value
    }
  };
  saveJournalEntries(updatedEntries);
  return updatedEntries;
};

export const deleteJournalEntry = (entries, date) => {
  const updatedEntries = { ...entries };
  delete updatedEntries[date];
  saveJournalEntries(updatedEntries);
  return updatedEntries;
};

export const deleteAllJournalEntries = () => {
  localStorage.removeItem(STORAGE_KEY);
  return {};
};