import React, { useState, useEffect } from 'react';
import TopBar from './components/TopBar/TopBar';
import JournalContent from './components/JournalContent';
import ContactIcon from './components/ContactIcon/ContactIcon';
import { useJournalEntries } from './hooks/useJournalEntries';
import styles from './App.module.css';

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { journalEntries, savedDates, updateEntry, deleteEntry, deleteAllEntries } = useJournalEntries();

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode) {
      setDarkMode(JSON.parse(savedDarkMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.body.classList.add('dark');
      document.body.style.backgroundImage = 'url("/dark-background.png")';
    } else {
      document.body.classList.remove('dark');
      document.body.style.backgroundImage = 'url("/background.png")';
    }
  }, [darkMode]);

  const exportAllEntriesToCSV = () => {
    if (Object.keys(journalEntries).length === 0) {
      alert('No entries to export.');
      return;
    }

    const headers = [
      "Date",
      "Morning Thoughts",
      "Top Priority for Today",
      "Exercise Plan",
      "Gratitude",
      "Accomplishments",
      "Creativity",
      "Plan Adherence",
      "Technology Use",
      "Bold Action",
      "Learning",
      "Exercise Completed",
      "Exercise Progress",
      "Learning Activity",
      "Learning Progress",
      "Meaningful Interactions",
      "Interactions Progress",
      "Financial Steps",
      "Financial Progress",
      "Tomorrow's Priority",
      "Self Challenge"
    ];

    let csvContent = headers.join(',') + '\n';

    Object.entries(journalEntries).forEach(([date, entry]) => {
      const rowData = [
        date,
        entry.morningThoughts || '',
        entry.topPriority || '',
        entry.exercise || '',
        entry.gratitude || '',
        entry.accomplishments || '',
        entry.creativity || '',
        entry.planAdherence || '',
        entry.technologyUse || '',
        entry.boldAction || '',
        entry.learning || '',
        entry.exerciseCompleted || '',
        entry.exerciseCompletedProgress || '',
        entry.learningActivity || '',
        entry.learningActivityProgress || '',
        entry.meaningfulInteractions || '',
        entry.meaningfulInteractionsProgress || '',
        entry.financialSteps || '',
        entry.financialStepsProgress || '',
        entry.tomorrowPriority || '',
        entry.selfChallenge || ''
      ];

      const formattedRowData = rowData.map(field => {
        const escapedField = String(field).replace(/"/g, '""');
        const singleLineField = escapedField.replace(/\n/g, ' ');
        return `"${singleLineField}"`;
      });

      csvContent += formattedRowData.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'journal_entries.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteAllEntries = () => {
    const confirmMessage = 
      "Hey there! 👋 Just a friendly heads-up:\n\n" +
      "You're about to delete all your journal entries. " +
      "This will erase everything you've written so far. " +
      "Are you sure you want to do this?\n\n" +
      "Don't worry, you can always start fresh if that's what you're after!";

    if (window.confirm(confirmMessage)) {
      deleteAllEntries();
      alert("All entries have been deleted. Here's to new beginnings! 🌟");
    } else {
      alert("No worries! Your entries are safe and sound. Keep on journaling! 📝");
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js';
    script.async = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      window.kofiWidgetOverlay.draw('hungryminded', {
        'type': 'floating-chat',
        'floating-chat.donateButton.text': 'Support me',
        'floating-chat.donateButton.background-color': '#323842',
        'floating-chat.donateButton.text-color': '#fff'
      });
  
      // Add touch event listener for mobile devices
      const kofiWidget = document.getElementById('kofi-widget-overlay');
      if (kofiWidget) {
        kofiWidget.addEventListener('touchstart', function() {
          this.style.left = '0';
        });
      }
    };
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={`${styles.App} ${darkMode ? styles.darkMode : ''}`}>
      <div className={styles.journalContent}>
        <TopBar 
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          exportAllEntriesToCSV={exportAllEntriesToCSV}
          deleteAllEntries={handleDeleteAllEntries}
        />
        <JournalContent 
          journalEntries={journalEntries}
          savedDates={savedDates}
          updateEntry={updateEntry}
          deleteEntry={deleteEntry}
        />
      </div>
      <div className={styles.infoIcon} title="Hover for disclaimer">
        <span className={styles.infoIconI}>ⓘ</span>
        <div className={styles.infoTooltip}>
          <h3>Disclaimer</h3>
          <p>This app was created by HungryMinded with the assistance of AI technology. Please be aware of the following:</p>
          <ul>
            <li>The app is experimental and may contain bugs or unexpected behavior.</li>
            <li>Your entries are stored locally in your browser and are not encrypted. Avoid entering sensitive information.</li>
            <li>The app's appearance and functionality may vary across devices, especially on mobile.</li>
            <li>Long-term data persistence is not guaranteed. Consider backing up important entries.</li>
            <li>Use this app at your own discretion and for non-critical journaling only.</li>
          </ul>
          <p>Despite these limitations, we hope you find the app useful for your journaling needs. Feedback and suggestions are welcome!</p>
        </div>
      </div>
      <div id="kofi-widget-overlay" className={styles.kofiWidgetOverlay}></div>
      <div className={styles.contactIconWrapper}>
        <ContactIcon />
      </div>
    </div>
  );
};

export default App;