import React from 'react';
import styles from './EveningReflection.module.css';

const EveningReflection = ({ journalEntry, updateEntry }) => {
  const renderTextareaField = (label, field, placeholder) => (
    <div className={styles.textareaField}>
      <label>{label}</label>
      <textarea
        value={journalEntry?.[field] || ''}
        onChange={(e) => updateEntry(field, e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  const renderInputField = (label, field, placeholder) => (
    <div className={styles.inputField}>
      <label>{label}</label>
      <input
        type="text"
        value={journalEntry?.[field] || ''}
        onChange={(e) => updateEntry(field, e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  const renderProgressBar = (label, field) => {
    const progress = journalEntry?.[`${field}Progress`] || 0;
    const hasBeenMoved = journalEntry?.[`${field}ProgressMoved`] || false;
    const smiley = hasBeenMoved ? getSmiley(progress) : null;

    return (
      <div key={field} className={styles.itemProgress}>
        <span className={styles.itemText}>{label}: {journalEntry?.[field]}</span>
        <div className={styles.progressContainer}>
          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={(e) => {
              const newProgress = parseInt(e.target.value);
              updateEntry(`${field}Progress`, newProgress);
              if (!hasBeenMoved) {
                updateEntry(`${field}ProgressMoved`, true);
              }
            }}
            className={styles.progressBar}
          />
          <span className={styles.progressLabel}>{progress}%</span>
          {smiley && <span className={styles.smiley}>{smiley}</span>}
        </div>
      </div>
    );
  };

  const getSmiley = (progress) => {
    if (progress === 0) return '😢';
    if (progress < 25) return '🙁';
    if (progress < 50) return '😐';
    if (progress < 75) return '🙂';
    if (progress < 100) return '😊';
    return '😄';
  };

  const renderTaskReview = () => {
    const tasks = journalEntry?.keyTasks || [];
    return (
      <div className={styles.taskReview}>
        <h3>Task/Goal Review</h3>
        <div className={styles.tasksBox}>
          {tasks.map((task, index) => (
            <div key={index} className={styles.taskItem}>
              <span>{task.task}</span>
              <input
                type="checkbox"
                checked={task.completed}
                onChange={() => {
                  const updatedTasks = [...tasks];
                  updatedTasks[index] = { ...task, completed: !task.completed };
                  updateEntry('keyTasks', updatedTasks);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const planItems = [
    { field: 'plannedExercise', label: 'Exercise' },
    { field: 'plannedLearning', label: 'Learning' },
    { field: 'plannedInteractions', label: 'Interactions' },
    { field: 'plannedFinancialSteps', label: 'Financial Steps' }
  ];

  const hasPlanItems = planItems.some(item => journalEntry?.[item.field]);

  return (
    <div className={styles.eveningReflection}>
      {renderTextareaField("What are you grateful for today?", "gratitude", "I'm grateful for...")}
      {renderTextareaField("What did you accomplish today?", "accomplishments", "Today I...")}
      {renderTaskReview()}
      {renderTextareaField("How did you utilize your creativity?", "creativity", "I expressed my creativity by...")}
      {renderTextareaField("Did you stick to your plan? If not, what obstacles did you face?", "planAdherence", "I followed my plan by... / I faced challenges with...")}
      
      {hasPlanItems && (
        <div className={styles.planProgressSection}>
          <h3>Plan Progress</h3>
          <div className={styles.planProgressBox}>
            {planItems.map(item => 
              journalEntry?.[item.field] ? renderProgressBar(item.label, item.field) : null
            )}
          </div>
        </div>
      )}

      {renderTextareaField("How did you minimize distractions and use technology purposefully?", "technologyUse", "I minimized distractions by...")}
      {renderInputField("Bold action or risk taken today", "boldAction", "Today I dared to...")}
      {renderInputField("What did you learn today?", "learning", "I learned about...")}
      
      <div className={styles.tomorrowPlan}>
        <h3>Tomorrow's Plan</h3>
        {renderInputField("Top priority for tomorrow", "tomorrowPriority", "Most important task")}
        {renderInputField("One thing to challenge yourself", "selfChallenge", "I will challenge myself to...")}
      </div>
    </div>
  );
};

export default EveningReflection;