import React, { useState, useEffect } from 'react';
import DateSelector from './DateSelector/DateSelector';
import MorningReflection from './MorningReflection/MorningReflection';
import EveningReflection from './EveningReflection/EveningReflection';
import Plan from './Plan/Plan';
import styles from '../App.module.css';

const JournalContent = ({ journalEntries, savedDates, updateEntry, deleteEntry }) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [activeTab, setActiveTab] = useState('morning');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [currentEntry, setCurrentEntry] = useState({});

  useEffect(() => {
    setCurrentEntry(journalEntries[selectedDate] || {});
  }, [selectedDate, journalEntries]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleUpdateEntry = (field, value) => {
    const updatedEntry = { ...currentEntry, [field]: value };
    setCurrentEntry(updatedEntry);
    updateEntry(selectedDate, field, value);
  };

  const handleSaveEntry = () => {
    Object.entries(currentEntry).forEach(([field, value]) => {
      updateEntry(selectedDate, field, value);
    });
    alert('Journal entry saved!');
  };

  return (
    <div>
      <DateSelector
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        savedDates={savedDates}
        isSelectOpen={isSelectOpen}
        setIsSelectOpen={setIsSelectOpen}
      />
      <div className={styles.tabButtons}>
        <button
          className={activeTab === 'morning' ? styles.active : ''}
          onClick={() => handleTabChange('morning')}
        >
          Morning Reflection
        </button>
        <button
          className={activeTab === 'evening' ? styles.active : ''}
          onClick={() => handleTabChange('evening')}
        >
          Evening Reflection
        </button>
      </div>
      {activeTab === 'morning' ? (
        <>
          <MorningReflection 
            journalEntry={currentEntry} 
            updateEntry={handleUpdateEntry} 
          />
          <Plan 
            journalEntry={currentEntry} 
            updateEntry={handleUpdateEntry} 
          />
        </>
      ) : (
        <EveningReflection 
          journalEntry={currentEntry} 
          updateEntry={handleUpdateEntry} 
        />
      )}
      <div className={styles.buttonContainer}>
        <button className={styles.saveButton} onClick={handleSaveEntry}>
          Save Journal Entry
        </button>
        <button className={styles.deleteButton} onClick={() => deleteEntry(selectedDate)}>
          Delete Entry
          <span role="img" aria-label="Delete entry">🗑️</span>
        </button>
      </div>
    </div>
  );
};

export default JournalContent;