import React from 'react';
import styles from './plan.module.css';

const Plan = ({ journalEntry, updateEntry }) => {
  const renderInputField = (label, field, placeholder) => (
    <div className={styles.inputField}>
      <label>{label}</label>
      <input
        type="text"
        value={journalEntry?.[field] || ''}
        onChange={(e) => updateEntry(field, e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <div className={styles.plan}>
      <h3>Plan</h3>
      {renderInputField("Planned exercise", "plannedExercise", "Type and duration of exercise")}
      {renderInputField("Planned learning activity", "plannedLearning", "Topic and time to spend")}
      {renderInputField("Planned meaningful interactions", "plannedInteractions", "Who and what")}
      {renderInputField("Planned steps towards financial goals", "plannedFinancialSteps", "Actions to take")}
    </div>
  );
};

export default Plan;