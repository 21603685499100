import React, { useState, useEffect } from 'react';
import styles from './MorningReflection.module.css';

const MorningReflection = ({ journalEntry, updateEntry }) => {
  const [tasks, setTasks] = useState(journalEntry?.keyTasks || []);

  useEffect(() => {
    setTasks(journalEntry?.keyTasks || []);
  }, [journalEntry]);

  const renderTextareaField = (label, field, placeholder) => (
    <div className={styles.textareaField}>
      <label>{label}</label>
      <textarea
        value={journalEntry?.[field] || ''}
        onChange={(e) => updateEntry(field, e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  const renderInputField = (label, field, placeholder) => (
    <div className={styles.inputField}>
      <label>{label}</label>
      <input
        type="text"
        value={journalEntry?.[field] || ''}
        onChange={(e) => updateEntry(field, e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );

  const handleTaskChange = (index, value) => {
    const newTasks = [...tasks];
    newTasks[index] = { ...newTasks[index], task: value };
    setTasks(newTasks);
    updateEntry('keyTasks', newTasks);
  };

  const addTask = () => {
    if (tasks.length < 5) {
      const newTasks = [...tasks, { task: '', completed: false }];
      setTasks(newTasks);
      updateEntry('keyTasks', newTasks);
    }
  };

  return (
    <div className={styles.morningReflection}>
      {renderTextareaField("Morning Thoughts", "morningThoughts", "Share your thoughts this morning...")}
      {renderInputField("Top priority for today", "topPriority", "Most important task")}
      {renderInputField("One thing to challenge yourself", "selfChallenge", "I will challenge myself to...")}

      <div className={styles.keyTasks}>
        <h3>Key Tasks/Goals for Today</h3>
        <div className={styles.tasksBox}>
          {tasks.map((task, index) => (
            <div key={index} className={styles.taskItem}>
              <input
                type="text"
                value={task.task}
                onChange={(e) => handleTaskChange(index, e.target.value)}
                placeholder={`Task ${index + 1}`}
              />
            </div>
          ))}
          {tasks.length < 5 && (
            <button onClick={addTask} className={styles.addTaskButton}>
              + Add Task
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MorningReflection;